module.exports = {
  current_address : 'NuvÆrende Adresse',
  address : 'Adresse',
  applay_now : 'Ansøg nu',
  allow_note : ' Jeg giver hermed samtykke til opbevaring og behandling af min persondata, som beskrevet i {corporationName} privatlivspolitik. Jeg giver hermed {corporationName} ret til at kontakte mig via enhver form for kommunikationskanal i relation til min boligansøgning. Jeg er bekendt med, at jeg til enhver tid kan trække mit samtykke tilbage ved at kontakte {corporationName}. Du kan tilgå {corporationName} privatlivspolitik ',
  birthdate : 'Fødselsdato',
  blakhus : '{corporationName}',
  city : 'By',
  country : 'Land',
  code : 'Kode',
  cpr_disclaimer : 'Desværre kan {corporationName} ikke godkende ansøgere, som er registrerede i RKI eller lignende.',
  cur_education : 'Nuværende uddannelse',
  cur_education_note : 'Registrer en uddannelse som du på nuværende tidspunkt er i gang med eller som du efter planen skal starte på indenfor de næste 6 måneder.',
  doc_disclaimer : '{corporationName} skal bruge dokumentation for din uddannelse og officiel dokumentation for din identitet (fx kørekort, sygersikring, osv) før vi vil færdigbehandle din ansøgning.',
  door : 'Dør',
  date : 'Dato',
  documents : 'Dokumenter',
  documents_note : 'Ingen dokumenter blev uploadet.',
  education_title : 'Uddannelsestitel',
  educational_institution : 'Uddannelsesinstitution',
  education : 'Uddannelsesinstitution',
  end_date : 'Slutdato',
  first_name : 'Fornavn',
  floor : 'Etage',
  field_required : 'Udfyld det obligatoriske felt',
  here : 'her',
  interested_from : 'Ønsket indflytningsdato',
  last_name : 'Efternavn',
  mail : 'Mail',
  max_rent: 'Maks. månedlig husleje',
  max_size : 'Maks. størrelse (m²)',
  month : 'Måned',
  min_rent: 'Min. månedlig husleje',
  min_size : 'Min. størrelse (m²)',
  none : 'Ingen',
  number : 'Nummer',
  no_education : 'Ingen uddannelse tilføjet.',
  name_title : 'Navn/titel',
  phone : 'Telefon',
  phone_note : 'Du skal registrere et telefon nr',
  press_enter_to_select: 'Tryk på enter for at vælge',
  press_enter_to_remove: 'Tryk på enter for at fjerne',
  profession : 'Profession',
  rooms : 'Antal værelser',
  new_graduate : 'Nyuddannet',
  professional_note : 'Ung professionel',
  register_education : 'Tilføj uddannelse',
  resident_note : 'Nuværende beboer hos {corporationName}',
  street : 'Gade',
  student : 'Studerende',
  student_note : 'Studerende',
  see_types : 'Se typer',
  start_date : 'Start dato',
  spec_wish : 'Særlige ønsker',
  select_country : 'Vælg land',
  type : 'type',
  type_note : 'Type C er handicap-venlig',
  upload_documents : 'Upload dokumenter',
  valid_email : 'Indtast en gyldig email',
  waiting_sign_up : 'Skriv dig op på ventelisten',
  year : 'År',
  zipcode : 'Postnr',
  error_header: 'Vi har fundet en fejl.',
  too_many_requests_error: 'For mange forsøg. Prøv igen lidt senere.',
  server_error: 'Server Fejl',
  close: 'luk',
  select_project: 'Vælg projektet',
  unit_groups: 'Grupper',
  unit_types: 'Type',
  optional: 'Valgfrit',
  property: 'Ejendom',
  nationality: 'Nationalitet',
  i_want_to_live_here: 'Jeg vil gerne bo her',
  select_unit_groups: 'Vælg boliggruppe (valgfrit)',
  select_unit_types: 'Vælg lejlighedstype (valgfrit)',
  select_one_or_more: 'Vælg én eller flere',
  selected: 'valgt',
  gender: 'køn',
  male: 'mand',
  female: 'kvinde',
  other: 'andet',
  preferences: 'Præferencer',
  select_date: 'Vælg dato',
  value_should_be_number: 'Værdien skal være et tal',
  value_should_be_decimal: 'Værdien skal være en decimal',
  zones: 'Zoner',
};
