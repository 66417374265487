<template>
    <main>
        <input type="file" id="fileInput" multiple ref="upload">
        <div id="environment-banner" v-if="isTestEnvironment">{{ testEnvironment }}</div>
        <div id="main-form" v-if="token && corporation" :style="`opacity: ${opacity}`">
            <form @submit.prevent="submitForm" enctype="multipart/form-data">
                <div id="waiting-form" class="p-0 p-md-4">
                    <h2 class="waiting-form-header mb-5">{{ translate('waiting_sign_up') }}</h2>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label for="first_name" class="customize-label">{{ translate('first_name') }} *</label>
                                <input
                                    id="first_name"
                                    type="text"
                                    v-model="form.first_name"
                                    :class="{ 'is-invalid-b': submitted && $v.form.first_name.$invalid, 'customize-input form-control': true }"
                                />
                                <div class="is-invalid-c" v-if="submitted && $v.form.first_name.$invalid">
                                    {{ translate('field_required') }}
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="last_name" class="customize-label">{{ translate('last_name') }} *</label>
                                <input
                                    id="last_name"
                                    type="text"
                                    v-model="form.last_name"
                                    :class="{ 'is-invalid-b': submitted && $v.form.last_name.$invalid, 'customize-input form-control': true }"
                                />
                                <div class="is-invalid-c" v-if="submitted && $v.form.last_name.$invalid">
                                    {{ translate('field_required') }}
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="email" class="customize-label">{{ translate('mail') }} *</label>
                                <input
                                    id="email"
                                    type="text"
                                    v-model="form.mail"
                                    :class="{ 'is-invalid-b': submitted && $v.form.mail.$invalid, 'customize-input form-control': true }"
                                />
                                <div class="is-invalid-c" v-if="submitted && $v.form.mail.$invalid">
                                    <span v-if="!$v.form.mail.required">{{ translate('field_required') }}</span>
                                    <span v-if="$v.form.mail.required && !$v.form.mail.email">{{ translate('valid_email') }}</span>
                                </div>
                            </div>

                            <label for="phone" class="customize-label">{{ translate('phone') }} *</label>
                            <div class="row">
                                <div class="col-3 pr-0">
                                    <select
                                        @change="setPlacholder"
                                        v-model="form.dial_code"
                                        :class="{ 'is-invalid-b': submitted && $v.form.dial_code.$invalid, 'customize-input form-control': true }"
                                        style="border-right: none"
                                    >
                                        <option value="">{{ translate('code') }}</option>
                                        <option
                                            v-for="(country, index) in countries" :key="index"
                                            :value="country.dial_code"
                                        >{{ country.code + ' ' + country.dial_code }}</option>
                                    </select>
                                    <div class="is-invalid-c" v-if="submitted && $v.form.dial_code.$invalid">
                                        {{ translate('field_required') }}
                                    </div>
                                </div>

                                <div class="col-9 pl-0">
                                    <div class="form-group">
                                        <input
                                            id="phone"
                                            type="text"
                                            v-model="form.phone"
                                            :class="{ 'is-invalid-b': submitted && $v.form.phone.$invalid, 'customize-input form-control m-0': true }"
                                            :placeholder="phone_placeholder"
                                        />
                                        <div class="is-invalid-c" v-if="submitted && $v.form.phone.$invalid">
                                            {{ translate('field_required') }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="wl-nationality-input" class="form-group">
                                <label for="nationality" class="customize-label">{{ translate('nationality') }}</label>
                                <select id="nationality" v-model="form.nationality" :class="{ 'customize-input form-control': true }">
                                    <option value="">{{ translate('select_country') }}</option>
                                    <option
                                        v-for="(country, index) in countries" :key="index"
                                        :value="country.code.toLowerCase()"
                                    >{{ country.name }}</option>
                                </select>
                            </div>

                            <div id="wl-is-resident-input" class="custom-control custom-checkbox mb-3">
                                <label for="resident" class="custom-container mb-3 custom-control-label customize-label">
                                    <span class="allow-style ml-0">
                                        {{ translate('resident_note', corporation.name) }}
                                    </span>
                                    <input type="checkbox" v-model="form.resident" id="resident">
                                    <span class="checkmark checkmark-top"></span>
                                </label>
                            </div>
                            <div class="form-group mb-0">
                                <label class="customize-label">{{ translate('current_address') }}</label>
                            </div>

                            <div class="form-group">
                                <label for="address" class="customize-label-sm">{{ translate('address') }} *</label>
                                <input
                                    id="address"
                                    type="text"
                                    v-model="form.street"
                                    :class="{ 'is-invalid-b': submitted && $v.form.street.$invalid, 'customize-input form-control': true }"
                                />
                                <div class="is-invalid-c" v-if="submitted && $v.form.street.$invalid">
                                    {{ translate('field_required') }}
                                </div>
                            </div>

                            <div class="row">
<!--                                <div class="col-4 pr-2">-->
<!--                                    <div class="form-group">-->
<!--                                        <label class="customize-label-sm">{{ translate('number') }}</label>-->
<!--                                        <input-->
<!--                                            type="text"-->
<!--                                            v-model="form.number"-->
<!--                                            :class="{ 'is-invalid-b': submitted && !$v.form.number.required, 'customize-input form-control': true }"-->
<!--                                        />-->
<!--                                        <div class="is-invalid-c" v-if="submitted && !$v.form.number.required">-->
<!--                                            {{ translate('field_required') }}-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="col-4 pr-2 pl-2">-->
<!--                                    <div class="form-group">-->
<!--                                        <label class="customize-label-sm">{{ translate('floor') }}</label>-->
<!--                                        <input class="customize-input form-control" type="text" v-model="form.floor" />-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="col-4 pl-2">-->
<!--                                    <div class="form-group">-->
<!--                                        <label class="customize-label-sm">{{ translate('door') }}</label>-->
<!--                                        <input class="customize-input form-control" type="text" v-model="form.door" />-->
<!--                                    </div>-->
<!--                                </div>-->
                            </div>
                            <div class="row">
                                <div class="col-4 pr-2">
                                    <div class="form-group">
                                        <label for="zipcode" class="customize-label-sm">{{ translate('zipcode') }} *</label>
                                        <input
                                            id="zipcode"
                                            type="text"
                                            v-model="form.zipcode"
                                            :class="{ 'is-invalid-b': submitted && $v.form.zipcode.$invalid, 'customize-input form-control': true }"
                                        />
                                        <div class="is-invalid-c" v-if="submitted && $v.form.zipcode.$invalid">
                                            {{ translate('field_required') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-8 pl-2">
                                    <div class="form-group">
                                        <label for="city" class="customize-label-sm">{{ translate('city') }} *</label>
                                        <input
                                            id="city"
                                            type="text"
                                            v-model="form.city"
                                            :class="{ 'is-invalid-b': submitted && $v.form.city.$invalid, 'customize-input form-control': true }"
                                        />
                                        <div class="is-invalid-c" v-if="submitted && $v.form.city.$invalid">
                                            {{ translate('field_required') }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="country" class="customize-label-sm">{{ translate('country') }} *</label>
                                        <select
                                            id="country"
                                            v-model="form.country"
                                            :class="{ 'is-invalid-b': submitted && $v.form.country.$invalid, 'customize-input form-control': true }"
                                        >
                                            <option value="">{{ translate('select_country') }}</option>
                                            <option
                                                v-for="(country, index) in countries" :key="index"
                                                :value="country.code.toLowerCase()"
                                            >{{ country.name }}</option>
                                        </select>
                                        <div class="is-invalid-c" v-if="submitted && $v.form.country.$invalid">
                                            {{ translate('field_required') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group mb-0">
                                <label class="customize-label">{{ translate('birthdate') }} *</label>
                            </div>
                            <div class="row">
                                <div class="col-6 col-lg-3 pr-2">
                                    <div class="form-group">
                                        <select
                                            v-model="form.month"
                                            :class="{ 'is-invalid-b': submitted && $v.form.month.$invalid, 'customize-input form-control': true }"
                                        >
                                            <option value="">{{ translate('month') }}</option>
                                            <option v-for="(month, index) in months" :key="index" :value="index">
                                                {{ month }}
                                            </option>
                                        </select>
                                        <div class="is-invalid-c" v-if="submitted && $v.form.month.$invalid">
                                            {{ translate('field_required') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-3 pr-lg-2 pl-2">
                                    <div class="form-group">
                                        <select
                                            class="customize-input form-control"
                                            v-model="form.date"
                                            :class="{ 'is-invalid-b': submitted && $v.form.date.$invalid, 'customize-input form-control': true }"
                                        >
                                            <option value="">{{ translate('date') }}</option>
                                            <option v-for="day in days" :key="day" :value="day">
                                                {{ day }}
                                            </option>
                                        </select>
                                        <div class="is-invalid-c" v-if="submitted && $v.form.date.$invalid">
                                            {{ translate('field_required') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-3 pr-2 pl-lg-2">
                                    <div class="form-group">
                                        <select
                                            v-model="form.year"
                                            :class="{ 'is-invalid-b': submitted && $v.form.year.$invalid, 'customize-input form-control': true }"
                                        >
                                            <option value="">{{ translate('year') }}</option>
                                            <option v-for="(year, index) in years(0)" :key="index" :value="year">
                                                {{ year }}
                                            </option>
                                        </select>
                                        <div class="is-invalid-c" v-if="submitted && $v.form.year.$invalid">
                                            {{ translate('field_required') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-lg-3 pl-2" id="cpr-group">
                                    <div class="form-group">
                                        <input
                                            id="cpr"
                                            class="customize-input form-control m-0"
                                            placeholder="NNNN"
                                            type="text"
                                            v-model="form.cpr"
                                        />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <p class="reg-note mb-0">{{ translate('cpr_disclaimer', corporation.name) }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-3 pr-2" id="gender-group">
                                    <label for="gender" class="customize-label">{{ translate('gender') }} *</label>
                                    <div class="form-group">
                                        <select
                                            id="gender"
                                            v-model="form.gender"
                                            :class="{ 'is-invalid-b': submitted && $v.form.gender.$invalid, 'customize-input form-control': true }"
                                        >
                                            <option value="">{{ translate('gender') }}</option>
                                            <option value="male">{{ translate('male') }}</option>
                                            <option value="female">{{ translate('female') }}</option>
                                            <option value="other">{{ translate('other') }}</option>
                                        </select>
                                        <div class="is-invalid-c" v-if="submitted && $v.form.gender.$invalid">
                                            {{ translate('field_required') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-9 pr-2">
                                    <label for="educationalInstitute" class="customize-label">{{ translate('educational_institution') }} </label>
                                    <div class="form-group">
                                        <input id="educationalInstitute" type="text" v-model="form.educationalInstitute"
                                            :class="{ 'is-invalid-b': submitted && $v.form.month.$invalid, 'customize-input form-control': true }"
                                        >
                                    </div>
                                </div>
                            </div>

                            <!-- Project and specific wishes for a single project -->
                            <div v-if="this.isSingleProject && this.mappedData.projects.length" class="row">
                                 <div class="col-12">
                                     <div class="customize-label">{{ mappedData.projects[0].project.name }}</div>
                                 </div>
                                  <div class="col-12 hoc-">
                                      <label :for="`project_${ mappedData.projects[0].project.id}`" class="custom-container custom-control-label">
                                            <input type="hidden" :value="mappedData.projects[0].project.id" :id="`project_${mappedData.projects[0].project.id}`" />
                                        </label>
                    
                                            <div v-if="mappedData.projects[0].unitGroups.length > 0">
                                                    <label class="customize-label-sm">{{ translate('select_unit_groups') }}</label>
                                                    <Multiselect :multiple="true" :searchable="false"
                                                        :value="selections.projects[0].unitGroups"
                                                        :options="mappedData.projects[0].unitGroups"
                                                        :close-on-select="false"
                                                        track-by="id"
                                                        label="name"
                                                        v-bind:placeholder="translate('select_one_or_more')"
                                                        @input="handleUnitGroupInput"
                                                        @remove="handleUnitGroupRemove"
                                                    >
                                                        <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="!isOpen && values.length">{{ values.length }} {{ translate('selected') }}</span></template>
                                                    </Multiselect>
                                                </div>

                                                <div v-if="mappedData.projects[0].unitTypes.length > 0">
                                                    <label class="customize-label-sm">{{ translate('select_unit_types') }}</label>
                                                    <Multiselect :multiple="true" :searchable="false"
                                                        :value="selections.projects[0].unitTypes"
                                                        :options="mappedData.projects[0].unitTypes"
                                                        :close-on-select="false"
                                                        track-by="id"
                                                        label="name"
                                                        v-bind:placeholder="translate('select_one_or_more')"
                                                        @input="handleUnitTypeInput"
                                                        @remove="handleUnitTypeRemove"
                                                    >
                                                        <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} {{ translate('selected') }}</span></template>
                                                    </Multiselect>
                                                </div>

                                                <a v-if="show_see_types" type="button c-pointer" :href="typeDetailsLink" target="_blank" class="btn bk_customize-btn-danger-bg btn-sm">{{ translate('see_types') }}</a>
                                                </div>

                            </div>

                            <!-- Project and specific wishes for multiple projects -->
                            <div v-else class="row">
                                <div class="col-12">
                                    <vsa-list :class="{'is-invalid-b': submitted && projects_error}">
                                    <!-- Here you can use v-for to loop through items  -->
                                        <vsa-item v-for="(projectData, index) in mappedData.projects" :key="index" :init-active="true">
                                            <vsa-heading>{{ projectData.project.name }}</vsa-heading>
                                            <vsa-content>
                                                <label :for="`project_${projectData.project.id}`" class="custom-container custom-control-label">
                                                    <span class="allow-style-n">{{ translate('i_want_to_live_here') }}</span>
                                                    <input type="checkbox" :value="projectData.project.id" v-model="selections.projects[index].selected" :id="`project_${projectData.project.id}`" @input="projectWasSelected" />
                                                    <span class="checkmark"></span>
                                                </label>
                                                <div v-if="projectData.unitGroups.length > 0">
                                                    <label class="hoc-accordion__label">{{ translate('select_unit_groups') }}</label>
                                                    <Multiselect :multiple="true" :searchable="false"
                                                        :value="selections.projects[index].unitGroups"
                                                        :options="mappedData.projects[index].unitGroups"
                                                        :close-on-select="false"
                                                        track-by="id"
                                                        label="name"
                                                        v-bind:placeholder="translate('select_one_or_more')"
                                                        @input="handleUnitGroupInput"
                                                        @remove="handleUnitGroupRemove"
                                                    >
                                                        <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="!isOpen && values.length">{{ values.length }} {{ translate('selected') }}</span></template>
                                                    </Multiselect>
                                                </div>

                                                <div v-if="projectData.unitTypes.length > 0">
                                                    <label class="hoc-accordion__label">{{ translate('select_unit_types') }}</label>
                                                    <Multiselect :multiple="true" :searchable="false"
                                                        :value="selections.projects[index].unitTypes"
                                                        :options="mappedData.projects[index].unitTypes"
                                                        :close-on-select="false"
                                                        track-by="id"
                                                        label="name"
                                                        v-bind:placeholder="translate('select_one_or_more')"
                                                        @input="handleUnitTypeInput"
                                                        @remove="handleUnitTypeRemove"
                                                    >
                                                        <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{ values.length }} {{ translate('selected') }}</span></template>
                                                    </Multiselect>
                                                </div>

                                                <a v-if="show_see_types" type="button c-pointer" :href="typeDetailsLink" target="_blank" class="btn bk_customize-btn-danger-bg">{{ translate('see_types') }}</a>
                                            </vsa-content>
                                        </vsa-item>
                                    </vsa-list>

                                    <div class="is-invalid-c" v-if="submitted && projects_error">
                                        {{ translate('field_required') }}
                                    </div>
                                    <!--
                                    <div class="form-group mb-0">
                                        <label class="customize-label">{{ translate('property') }}</label>
                                    </div>
                                    <div class="form-group">
                                        <select
                                            class="customize-input form-control"
                                            :class="{ 'is-invalid-b': submitted && !$v.form.project.required, 'customize-input form-control': true }"
                                            v-model="form.project"
                                        >
                                            <option value="" selected>{{ translate('select_project') }}</option>

                                            <option v-for="(project, index) in projects" :key="index" :value="project.id">
                                                {{ project.name }}
                                            </option>
                                        </select>

                                        <div class="is-invalid-c" v-if="submitted && !$v.form.project.required">
                                            {{ translate('field_required') }}
                                        </div>
                                    </div>
                                    -->
                                </div>
                            </div>

                            <!-- Specific wishes -->
                            <div class="row">
                                <div class="col-12">
                                    <!-- Unit groups -->
                                    <template v-if="waitingListConfig.allowUnitGroup && projectUnitGroups.length > 0">
                                        <div class="form-group mb-0"><label class="customize-label">{{ translate('unit_groups') }} ({{ translate('optional') }})</label></div>

                                        <div class="row">
                                            <div class="col-12 col-lg-6" v-for="(unitGroup, index) in projectUnitGroups" :key="index">
                                                <div class="custom-control custom-checkbox mb-3 customize-checkbox">
                                                    <label :for="`unit_group_${unitGroup.id}`" class="custom-container custom-control-label">
                                                        <span class="allow-style-n">{{ unitGroup.name }}</span>
                                                        <input type="checkbox" :value="unitGroup.id" v-model="form.unitGroups" :id="`unit_group_${unitGroup.id}`">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <div class="row mb-3" v-if="projectUnitTypes.length > 0 && projectUnitGroups.length > 0">
                                        <div class="col-12"><div class="unberline-border"></div></div>
                                    </div>

                                    <!-- Unit types -->
                                    <template v-if="waitingListConfig.allowUnitTypes && projectUnitTypes.length > 0">
                                        <div class="form-group mb-0"><label class="customize-label">{{ translate('unit_types') }} ({{ translate('optional') }})</label></div>

                                        <div class="row">
                                            <div class="col-12 col-lg-6" v-for="(unitType, index) in projectUnitTypes" :key="index">
                                                <div class="custom-control custom-checkbox mb-3 customize-checkbox">
                                                    <label :for="`unit_type_${unitType.id}`" class="custom-container custom-control-label">
                                                        <span class="allow-style-n">{{ unitType.name }}</span>
                                                        <input type="checkbox" :value="unitType.id" v-model="form.unitTypes" :id="`unit_type_${unitType.id}`">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>

                                <!-- <div class="col-12 mt-3" v-if="typeDetailsLink !== '' && (waitingListConfig.allowUnitTypes || waitingListConfig.allowUnitGroup)">
                                    <a type="button c-pointer" :href="typeDetailsLink" target="_blank" class="btn bk_customize-btn-danger-bg">
                                       {{ translate('see_types') }}
                                    </a>
                                    <p class="reg-note"><i>{{ translate('type_note') }}</i></p>
                                </div> -->
                            </div>

                            <div class="row my-2">
                                <div class="form-group mb-0 col-12">
                                    <label class="customize-label">{{ translate('interested_from') }} *</label>
                                </div>
                                <div class="form-group mb-0 col-12">
                                    <datepicker
                                        :clear-button="true"
                                        :input-class="{ 'is-invalid-b': submitted && !$v.form.interestedFrom.required, 'customize-picker': true }"
                                        calendar-class="customize-picker-calendar"
                                        :value="pickerProp.today"
                                        :disabled-dates="pickerProp.disabledDates"
                                        @selected="pickerSeleced"
                                        @cleared="pickerCleared"
                                        :language="def_lang === 'dk' ? pickerProp.languages.da : pickerProp.languages.en"
                                        :placeholder="translate('select_date')"
                                    >
                                    </datepicker>
                                    <div class="is-invalid-c" v-if="submitted && !$v.form.interestedFrom.required">
                                        {{ translate('field_required') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-12">
                            <div class="unberline-border"></div>
                        </div>
                    </div>
                </div>

                <div class="p-0 p-md-4 pt-4 col-md-6" v-if="additionalPreferenceEnabled()" id="wl-additional-preferences">

                    <div class="row" id="wl-apartment-size">
                        <div class="col-6" id="wl-min-apartment-size-input" v-if="waitingListConfig.minApartmentSizeEnabled">
                            <div class="form-group">
                                <label for="min-apartment-size" class="customize-label-sm">{{ translate('min_size') }}</label>
                                <input
                                    id="min-apartment-size"
                                    type="number"
                                    v-model="form.minApartmentSize"
                                    :class="{'customize-input form-control': true }"
                                    step="1"
                                />
                            </div>
                        </div>
                        <div class="col-6" id="wl-max-apartment-size-input" v-if="waitingListConfig.maxApartmentSizeEnabled">
                            <div class="form-group">
                                <label for="max-apartment-size" class="customize-label-sm">{{ translate('max_size') }}</label>
                                <input
                                    id="max-apartment-size"
                                    type="number"
                                    v-model="form.maxApartmentSize"
                                    :class="{'customize-input form-control': true }"
                                    step="1"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="row" id="wl-rent-input">
                        <div class="col-6 hoc-waiting-list-min-rent" v-if="waitingListConfig.minRentEnabled">
                            <div class="form-group">
                                <label for="min-rent" class="customize-label-sm">{{ translate('min_rent') }}</label>
                                <input
                                    id="min-rent"
                                    type="number"
                                    v-model="form.minRent"
                                    :class="{'customize-input form-control': true }"
                                    step="0.01"
                                />
                            </div>
                        </div>
                        <div class="col-6" id="wl-max-rent-input" v-if="waitingListConfig.maxRentEnabled">
                            <div class="form-group">
                                <label for="max-rent" class="customize-label-sm">{{ translate('max_rent') }}</label>
                                <input
                                    id="max-rent"
                                    type="number"
                                    v-model="form.maxRent"
                                    :class="{'customize-input form-control': true }"
                                    step="0.01"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="row" id="wl-list-rooms-input" v-if="waitingListConfig.numberOfRoomsEnabled">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="rooms" class="customize-label-sm">{{ translate('rooms') }}</label>
                                <input
                                    id="rooms"
                                    type="number"
                                    v-model="form.rooms"
                                    :class="{'customize-input form-control': true }"
                                    step="0.01"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="row " id="wl-zones-input" v-if="waitingListConfig.zoneSelectionEnabled && zoneOptions.length > 0">
                         <div class="col-12">
                        <label class="customize-label-sm">{{ translate('preferences') }}</label>
                        <Multiselect :multiple="true" :searchable="false"
                            :value="selectedZones"
                            :options="zoneOptions"
                            :close-on-select="false"
                            :select-label="translate('press_enter_to_select')"
                            :deselect-label="translate('press_enter_to_remove')"
                            track-by="id"
                            label="name"
                            v-bind:placeholder="translate('select_one_or_more')"
                            @input="handleZoneInput"
                        >
                            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="!isOpen && values.length">{{ values.length }} {{ translate('selected') }}</span></template>
                        </Multiselect>
                         </div>
                    </div>

                     <div class="row mt-5">
                        <div class="col-12">
                            <div class="unberline-border"></div>
                        </div>
                    </div>

                </div>

                <div id="education-form" class="p-0 p-md-4 pt-4">
                    <div class="row">
                        <div class="col-12 mb-0" v-if="educationNeeded(false)">
                            <p
                                v-if="Object.keys(education).length > 0"
                                @click="addEducation"
                                class="float-right remove-def mb-0"
                            >
                                <span>
                                  <i class="demo-icon icon-cancel"></i>
                                </span>
                            </p>
                        </div>

                        <div class="col-12 col-md-6">
                            <div v-if="educationNeeded(false)">
                                <div class="form-group">
                                    <label class="customize-label">{{ translate('cur_education') }}</label>
                                    <p class="reg-note">{{ translate('cur_education_note') }}</p>
                                </div>
                                <div class="form-group">
                                    <div class="reg-note" v-if="reg_educations.length === 0">
                                        {{ translate('no_education') }}
                                    </div>
                                    <div v-for="(reg_education, index) in reg_educations" :key="index">
                                        <p>
                                            <span @click="editEducation(index)" class="education-titles c-pointer">
                                            {{index+1}}. {{reg_education.name_title}}
                                            </span>
                                            <span class="c-pointer ml-3" style="display:inline-block" @click="deleteEducation(index)">
                                            <i class="ml-4 demo-icon icon-trash-empty customize-icon"></i>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <label class="customize-label">{{ translate('profession') }} *</label>
                            <div class="custom-control custom-checkbox mb-3">
                                <label for="student" class="custom-container mb-3 custom-control-label customize-label">
                                    <span class="allow-style ml-0">{{ translate('student_note') }}</span>
                                    <input type="radio" name="professional" value="stud" v-model="form.professional" id="student">
                                    <span class="checkmark checkmark-top"></span>
                                </label>
                            </div>
                            <div class="custom-control custom-checkbox mb-3">
                                <label for="graduate" class="custom-container mb-3 custom-control-label customize-label">
                                    <span class="allow-style ml-0">{{ translate('new_graduate') }}</span>
                                    <input type="radio" name="graduate" value="grad" v-model="form.professional" id="graduate">
                                    <span class="checkmark checkmark-top"></span>
                                </label>
                            </div>
                            <div class="custom-control custom-checkbox mb-3">
                                <label for="professional" class="custom-container mb-3 custom-control-label customize-label">
                                    <span class="allow-style ml-0">{{ translate('professional_note') }}</span>
                                    <input type="radio" name="professional" value="prof" v-model="form.professional" id="professional">
                                    <span class="checkmark checkmark-top"></span>
                                </label>
                            </div>


                            <div class="form-group mt-5">
                                <label class="customize-label">{{ translate('documents') }}</label>
                                <div v-if="this.files.length">
                                    <div class="attached-doc" v-for="(file, index) in this.files" :key="index">
                                        <p class="mb-1 mt-2">
                                            <i class="attached-doc-i demo-icon icon-doc-text"></i>
                                            {{ file.name }}
                                            <span @click="removeFile(index)">
                                                <i class="attached-doc-x demo-icon icon-cancel float-right"></i>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div v-else>
                                    <p class="reg-note">{{ translate('documents_note') }}</p>
                                </div>
                            </div>

                            <div class="form-group mt-5">
                                <button type="button" @click="$refs.upload.click()" class="btn customize-btn-def">
                                    {{ translate('upload_documents') }}
                                    <i class="demo-icon icon-upload-cloud"></i>
                                </button>
                                <p class="reg-note mt-2">{{ translate('doc_disclaimer', corporation.name) }}</p>
                            </div>
                        </div>
                        <div class="col-12 col-md-6" v-if="educationNeeded(false)">
                            <div v-if="Object.keys(education).length !== 0">
                                <div class="form-group">
                                    <label class="customize-label">
                                        {{ education.order === -1 ? reg_educations.length + 1 : education.order + 1}}. {{ translate('education') }}
                                    </label>
                                    <input
                                        type="text"
                                        v-model="education.name_title"
                                        :class="{ 'is-invalid-b': ed_submited && $v.education.name_title.$invalid, 'customize-input form-control': true }"
                                        :placeholder=" translate('name_title')"
                                    />
                                    <div class="is-invalid-c" v-if="ed_submited && $v.education.name_title.$invalid">
                                        {{ translate('field_required') }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group mb-0">
                                            <label class="customize-label-sm mb-0">{{ translate('start_date') }}</label>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <select
                                                @change="educationStartMonthChange"
                                                :class="{ 'is-invalid-b': ed_submited && $v.education.ed_s_month.$invalid, 'customize-input form-control': true }"
                                                v-model="education.ed_s_month"
                                            >
                                                <option value="">{{ translate('month') }}</option>
                                                <option v-for="(month, index) in months" :key="index" :value="index">
                                                    {{ month }}
                                                </option>
                                            </select>
                                            <div class="is-invalid-c" v-if="ed_submited && $v.education.ed_s_month.$invalid">
                                                {{ translate('field_required') }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <select
                                                :class="{ 'is-invalid-b': ed_submited && $v.education.ed_s_date.$invalid, 'customize-input form-control': true }"
                                                v-model="education.ed_s_date"
                                            >
                                                <option value="">{{ translate('date') }}</option>
                                                <option v-for="day in education.ed_s_days" :key="day" :value="day">
                                                    {{ day }}
                                                </option>
                                            </select>
                                            <div class="is-invalid-c" v-if="ed_submited && $v.education.ed_s_date.$invalid">
                                                {{ translate('field_required') }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <select
                                                :class="{ 'is-invalid-b': ed_submited && $v.education.ed_s_year.$invalid, 'customize-input form-control': true }"
                                                v-model="education.ed_s_year"
                                            >
                                                <option value="">{{ translate('year') }}</option>
                                                <option v-for="(year, index) in years(1)" :key="index" :value="year">
                                                    {{ year }}
                                                </option>
                                            </select>
                                            <div class="is-invalid-c" v-if="ed_submited && $v.education.ed_s_year.$invalid">
                                                {{ translate('field_required') }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group mb-0">
                                            <label class="customize-label-sm mb-0">{{ translate('end_date') }}</label>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <select
                                                @change="educationEndMonthChange"
                                                :class="{ 'is-invalid-b': ed_submited && $v.education.ed_e_month.$invalid, 'customize-input form-control': true }"
                                                v-model="education.ed_e_month"
                                            >
                                                <option value="">{{ translate('month') }}</option>
                                                <option v-for="(month, index) in months" :key="index" :value="index">
                                                    {{ month }}
                                                </option>
                                            </select>
                                            <div class="is-invalid-c" v-if="ed_submited && $v.education.ed_e_month.$invalid">
                                                {{ translate('field_required') }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <select
                                                :class="{ 'is-invalid-b': ed_submited && $v.education.ed_e_date.$invalid, 'customize-input form-control': true }"
                                                v-model="education.ed_e_date"
                                            >
                                                <option value="">{{ translate('date') }}</option>
                                                <option v-for="day in education.ed_e_days" :key="day" :value="day">
                                                    {{ day }}
                                                </option>
                                            </select>
                                            <div
                                                class="is-invalid-c"
                                                v-if="ed_submited && $v.education.ed_e_date.$invalid">
                                                {{ translate('field_required') }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <select
                                                :class="{ 'is-invalid-b': ed_submited && $v.education.ed_e_year.$invalid, 'customize-input form-control': true }"
                                                v-model="education.ed_e_year"
                                            >
                                                <option value="">{{ translate('year') }}</option>
                                                <option v-for="(year, index) in years(10)" :key="index" :value="year">
                                                    {{ year }}
                                                </option>
                                            </select>
                                            <div class="is-invalid-c" v-if="ed_submited && $v.education.ed_e_year.$invalid">
                                                {{ translate('field_required') }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="customize-label-sm">{{ translate('educational_institution') }}</label>
                                    <input
                                        type="text"
                                        v-model="education.ed_institution"
                                        :class="{ 'is-invalid-b': ed_submited && $v.education.ed_institution.$invalid, 'customize-input form-control': true }"
                                    />
                                    <div class="is-invalid-c" v-if="ed_submited && $v.education.ed_institution.$invalid">
                                        {{ translate('field_required') }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label class="customize-label-sm">{{ translate('zipcode') }}</label>
                                            <input
                                                type="text"
                                                v-model="education.ed_zipcode"
                                                :class="{ 'is-invalid-b': ed_submited && $v.education.ed_zipcode.$invalid, 'customize-input form-control': true }"
                                            />
                                            <div class="is-invalid-c" v-if="ed_submited && $v.education.ed_zipcode.$invalid">
                                                {{ translate('field_required') }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <p v-if="ed_error" class="reg-note-danger mb-3">{{ed_error_note}}</p>
                                            <button
                                                type="button"
                                                @click="registerEducation(education.order)"
                                                class="btn bk_customize-btn-danger"
                                            >
                                                {{ translate('register_education') }}
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <p
                                            v-if="education.order !== -1"
                                            class="btn customize-text-danger float-right"
                                            @click="deleteEducation(education.order)"
                                        >{{ translate('del_education') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-12">
                            <div class="unberline-border"></div>
                        </div>
                    </div>
                </div>
                <div class="row p-0 px-md-4 ">
                    <div class="col-12">
                        <div class="form-group mt-5">
                            <p v-if="submit_error" class="reg-note-danger mb-3">{{submit_error_note}}</p>
                            <button type="submit" class="btn bk_customize-btn-danger-bg">{{ translate('applay_now') }}</button>
                        </div>
                        <div class="custom-control custom-checkbox mb-3">
                            <label for="allow" class="custom-container custom-control-label">
                                <span style="font-size:17px" class="allow-style">
                                    {{ translate('allow_note', corporation.name) }}
                                    <b><a class="policy-link" :href="privacyPolicyLink" target="_blank">{{ translate('here') }}</a></b>
                                </span>
                                <input type="checkbox" v-model="form.allow" id="allow">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </form>
            <div v-if="msg_data" :class="msg_data_class">
                <p> {{ msg_data_text }}</p>
            </div>
        </div>

        <template v-if="responseErrorMessage !== null">
            <div class="bk_overlay" v-if="responseErrorMode === 'modal'">
                <div class="bk_overlay__body">
                    <h2 class="bk_error-header">{{ translate('error_header') }} </h2>
                    <p class="bk_error-text">{{ responseErrorMessage }}</p>

                    <div class="text-center">
                        <button
                            type="button"
                            @click="closeErrorOverlay"
                            class="btn bk_customize-btn-danger mt-5 text-center"
                        >
                            {{ translate('close') }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="bk_request-error-message" v-else>
                <h2 class="bk_error-header">{{ translate('error_header') }} </h2>
                <p class="bk_error-text">{{ responseErrorMessage }}</p>
            </div>
        </template>
    </main>
</template>

<script>
import axios from 'axios';
import {required, email} from 'vuelidate/lib/validators';
import countries from '../countries';
import dn_lang from '../lang/dn/messages';
import en_lang from '../lang/en/messages';
import Datepicker from 'vuejs-datepicker';
import {en, da}  from 'vuejs-datepicker/dist/locale';
import { VsaList, VsaItem, VsaHeading, VsaContent, VsaIcon } from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
    name: 'MainForm',
    components: {
        Datepicker,
        VsaList,
        VsaItem,
        VsaHeading,
        VsaContent,
        VsaIcon,
        Multiselect
    },
    props: {
        clientId: null,
        definedLanguage: null,
        redirectUrl: null,
        clientSecret: null,
        environment: null,
        showSeeTypes: null,
        genderEnabled: null,
        detailedEducation: null          
    },
    validations: () => {
        return {
            form: {
                first_name: {required},
                last_name: {required},
                mail: {required, email},
                phone: {required},
                street: {required},
                city: {required},
                country: {required},
                dial_code: {required},
                // number: {required},
                zipcode: {required},
                month: {required},
                date: {required},
                year: {required},
                interestedFrom: {required},
                gender: {required},
            },
            education: {
                name_title: {required},
                ed_s_month: {required},
                ed_s_date: {required},
                ed_s_year: {required},
                ed_e_month: {required},
                ed_e_date: {required},
                ed_e_year: {required},
                ed_institution: {required},
                ed_zipcode: {required},
            },     
        }
    },
    data() {
        return {
            isTestEnvironment: false,
            testEnvironment: null,
            corporation: null,
            waitingListConfig: null,
            isSingleProject: false,
            projects: [],
            unitGroups: [],
            unitTypes: [],
            mappedData: {
                projects: [],
            },
            selections: {
                projects: [],
            },
            zoneOptions: [],
            selectedZones: [],
            form: {
                projects: [],
                unitGroups: [],
                unitTypes: [],
                first_name: '',
                last_name: '',
                mail: '',
                phone: '',
                resident: false,
                professional: 'stud',
                street: '',
                number: '',
                floor: '',
                door: '',
                zipcode: '',
                city: '',
                country: '',
                nationality: '',
                dial_code: '+45',
                month: '',
                date: '',
                year: '',
                cpr: '',
                interestedFrom: '',
                allow: false,
                gender: '',
                educationalInstitute: '',
            },
            education: {
                name_title: '',
                ed_s_month: '',
                ed_s_date: '',
                ed_s_year: '',
                ed_s_days: 0,
                ed_e_month: '',
                ed_e_date: '',
                ed_e_year: '',
                ed_e_days: 0,
                ed_institution: '',
                ed_zipcode: '',
                order: -1,
            },
            reg_educations: [],
            files: [],
            token: false,
            submitted: false,
            msg_data: false,
            msg_data_text: '',
            msg_data_class: '',
            ed_submited: false,
            ed_error: false,
            ed_error_note: '',
            submit_error: false,
            projects_error: false,
            submit_error_note: '',
            show_see_types: false,
            phone_placeholder: 'XX XX XX XX',
            opacity: 1,
            days: 0,
            thirty: [3, 5, 8, 10],
            thirtyone: [0, 2, 4, 6, 7, 9, 11],
            def_lang: 'en',
            responseErrorMessage: null,
            responseErrorMode: 'fullscreen',
            apiUrl: '',
            pickerProp: {
                today: '',
                disabledDates: {
                    customPredictor: function(date) {
                        let today = new Date();
                        today.setDate(today.getDate() - 1);
                        if(date.getTime() <= today.getTime()){
                            return true;
                        }
                    },
                },
                languages: {
                    en: en,
                    da: da,
                }
            },
        }
    },
    watch: {
        'form.month'() {
            if (this.form.month === '') {
                this.days = 0
            } else if (this.thirty.includes(this.form.month)) {
                this.days = 30
            } else if (this.thirtyone.includes(this.form.month)) {
                this.days = 31
            } else {
                this.days = 29
            }
        },
        'form.project'() {
            this.form.unitGroups = [];
            this.form.unitTypes  = [];
        }
    },
    computed: {
        countries() {
            return countries;
        },
        months() {
            return this.def_lang === 'dk'
                ? ["Januar", "Februar", "Marts", "April", "Maj", "Juni", "Juli", "August", "September", "Oktober", "November", "December"]
                : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        },
        projectUnitGroups() {
            return this.form.project === ''
                ? []
                : this.unitGroups.filter(group => group.projectId === this.form.project).sort((first, second) => {
                    if (first.name > second.name) {
                        return 1;
                    }

                    if (first.name < second.name) {
                        return -1;
                    }

                    return 0;
                });
        },
        projectUnitTypes() {
            return this.form.project === ''
                ? []
                : this.unitTypes.filter(group => group.projectId === this.form.project).sort((first, second) => {
                    if (first.name > second.name) {
                        return 1;
                    }

                    if (first.name < second.name) {
                        return -1;
                    }

                    return 0;
                });
        },
        privacyPolicyLink() {
            const language        = this.def_lang === 'dk' ? 'da' : 'en';
            let privacyPolicyLink = '';

            if (typeof this.waitingListConfig !== 'undefined' && this.waitingListConfig.translations.length > 0) {
                privacyPolicyLink = this.waitingListConfig.translations.find(translation => translation.languageKey === language).privacyPolicyUrl;
            }

            return privacyPolicyLink;
        },
        typeDetailsLink() {
            const language        = this.def_lang === 'dk' ? 'da' : 'en';
            let privacyPolicyLink = '';

            if (typeof this.waitingListConfig !== 'undefined' && this.waitingListConfig.translations.length > 0) {
                privacyPolicyLink = this.waitingListConfig.translations.find(translation => translation.languageKey === language).typeDetailsUrl;
            }

            return privacyPolicyLink;
        }
    },
    methods: {
        projectWasSelected() {
            if (this.submitted) {
                this.projects_error = this.projectSelectionValidates();
            }
        },
        populateSelection() {
            this.form.projects = [];
            this.form.unitTypes = [];
            this.form.unitGroups = [];

            for (const project of this.selections.projects) {
                if (!project.selected === true || !project.projectId) {
                    continue;
                }

                this.form.projects.push(project.projectId);

                if (project.unitTypes.length) {
                    for (const unitType of project.unitTypes) {
                        this.form.unitTypes.push(unitType.id);
                    }
                }

                if (project.unitGroups.length) {
                    for (const unitGroup of project.unitGroups) {
                        this.form.unitGroups.push(unitGroup.id);
                    }
                }
            }
        },
        projectSelectionValidates() {
            console.log(this.projects.length);

            if (this.projects.length == 0) {
                return true;
            }

            for (const project of this.selections.projects) {
                if (project.selected === true) {
                    return true;
                }
            }

            return false;
        },
        handleUnitTypeInput(selections) {
            if (!selections.length) {
                return;
            }

            const firstType = selections[0];
            const projectId = firstType.projectId;
            let selection = this.selections.projects.find((selection) => { return selection.projectId === projectId; });

            selection.unitTypes.splice(0);

            for (const type of selections) {
                selection.unitTypes.push(type);
            }
        },
        handleUnitTypeRemove(unitTypeToRemove) {
            let selection = this.selections.projects.find((selection) => { return selection.projectId === unitTypeToRemove.projectId; });

            var removeIndex = selection.unitTypes
                .map(function(item) { return item.id; })
                .indexOf(unitTypeToRemove.id);

            // remove object
            selection.unitTypes.splice(removeIndex, 1);
        },
        handleUnitGroupInput(selections) {
            if (!selections.length) {
                return;
            }

            const firstGroup = selections[0];
            const projectId = firstGroup.projectId;
            let selection = this.selections.projects.find((selection) => { return selection.projectId === projectId; });

            selection.unitGroups.splice(0);

            for (const group of selections) {
                selection.unitGroups.push(group);
            }
        },
        handleUnitGroupRemove(unitGroupToRemove) {
            let selection = this.selections.projects.find((selection) => { return selection.projectId === unitGroupToRemove.projectId; });

            var removeIndex = selection.unitGroups
                .map(function(item) { return item.id; })
                .indexOf(unitGroupToRemove.id);

            // remove object
            selection.unitGroups.splice(removeIndex, 1);
        },
        handleZoneInput(selections) {
            this.selectedZones = selections;
        },
        setZonesOptions(zoneOptions) {
            this.zoneOptions = zoneOptions;
        },
        pickerSeleced(date) {
            this.form.interestedFrom = date;
        },
        pickerCleared() {
            this.form.interestedFrom = '';
        },
        closeErrorOverlay() {
            this.responseErrorMessage = null;
            this.responseErrorMode = 'fullscreen';
        },
        years(plusCount) {
            let curDate = new Date();
            let curYear = curDate.getFullYear() + plusCount;
            let years = {};
            let i = 0;
            for (let index = curYear; index > curYear - 80; index--) {
                years[i] = index;
                i++
            }
            return years;
        },
        translate(key, replacment = null) {
            let translation = (this.def_lang === 'dk') ? dn_lang[key] : en_lang[key];

            if (replacment !== null) {
                translation = translation.replace(/{.*?}/gm, replacment);
            }

            return translation;
        },
        educationNeeded(needed) {
            return needed;
        },
        fileAttached() {
            for (let index = 0; index < this.$refs.upload.files.length; index++) {
                this.files.push(this.$refs.upload.files[index])
            }
        },
        removeFile(index) {
            this.files = this.files.filter((value, i) => {
                return i != index;
            });
            if (this.files.length === 0) this.files = []
        },
        educationStartMonthChange() {
            if (this.education.ed_s_month === '') {
                this.education.ed_s_days = 0
            } else if (this.thirty.includes(this.education.ed_s_month)) {
                this.education.ed_s_days = 30
            } else if (this.thirtyone.includes(this.education.ed_s_month)) {
                this.education.ed_s_days = 31
            } else {
                this.education.ed_s_days = 29
            }
        },
        educationEndMonthChange() {
            if (this.education.ed_e_month === '') {
                this.education.ed_e_days = 0
            } else if (this.thirty.includes(this.education.ed_e_month)) {
                this.education.ed_e_days = 30
            } else if (this.thirtyone.includes(this.education.ed_e_month)) {
                this.education.ed_e_days = 31
            } else {
                this.education.ed_e_days = 29
            }
        },
        deleteEducation(index) {
            let resetOrder = false;
            this.reg_educations = this.reg_educations.filter((value, i) => {
                if (this.education.order === value.order) resetOrder = value.order;
                return i != index;
            });
            for (let i = 0; i < this.reg_educations.length; i++) {
                if (resetOrder === this.reg_educations[i].order) this.education.order = i;
                this.reg_educations[i].order = i;
            }
            if (resetOrder === index) this.education = {};
            this.addEducation();
        },
        addEducation() {
            this.education = {
                name_title: '',
                ed_s_month: '',
                ed_s_date: '',
                ed_s_year: '',
                ed_s_days: 0,
                ed_e_month: '',
                ed_e_date: '',
                ed_e_year: '',
                ed_e_days: 0,
                ed_institution: '',
                ed_zipcode: '',
                order: -1
            };
        },
        setPlacholder() {
            let x_length = this.form.dial_code.length - 1;
            if (this.form.dial_code == '+45') x_length = 3;
            if (this.form.dial_code !== '') {
                this.phone_placeholder = '';
                for (let i = 1; i < 12 - x_length; i++) {
                    this.phone_placeholder += i % 2 === 0 ? 'X ' : 'X';
                }
            } else {
                this.phone_placeholder = this.translate('phone_note')
            }
        },
        additionalPreferenceEnabled() {
            const { minApartmentSizeEnabled, maxApartmentSizeEnabled, minRentEnabled, maxRentEnabled, numberOfRoomsEnabled, zoneSelectionEnabled } = this.waitingListConfig;
            return minApartmentSizeEnabled 
                || maxApartmentSizeEnabled
                || minRentEnabled
                || maxRentEnabled
                || numberOfRoomsEnabled
                || (zoneSelectionEnabled && this.zoneOptions.length > 0);
        },
        initializeEnabledAdditionalPreferences() {
            const { minApartmentSizeEnabled, maxApartmentSizeEnabled, minRentEnabled, maxRentEnabled, numberOfRoomsEnabled, zoneSelectionEnabled, optionChoices } = this.waitingListConfig;

            if ( minApartmentSizeEnabled ) {
                this.form.minApartmentSize = '';
            }
            if ( maxApartmentSizeEnabled ) {
                this.form.maxApartmentSize = '';
            }
            if ( minRentEnabled ) {
                this.form.minRent = '';
            }
            if ( maxRentEnabled ) {
                this.form.maxRent = '';
            }
            if ( numberOfRoomsEnabled ) {
                this.form.rooms = '';
            }
            if ( zoneSelectionEnabled ) {
                this.form.selectedZones = [];
                this.initializeZoneDropdownOptions(optionChoices);
            }
        },
        initializeZoneDropdownOptions(optionChoices) {
            const languageCode = this.def_lang == 'dk' ? 'da' : this.def_lang;
            if (optionChoices) {
                this.zoneOptions = optionChoices.map((option) => {
                    let translatedOptionChoice = option.translations.find(translation => translation.language === languageCode);
                    return {
                        id: option.id,
                        name: translatedOptionChoice ? translatedOptionChoice.description : option.translations.find(translation => translation.language === 'en').description
                    };
                });
                this.zoneOptions.sort((zoneA, zoneB)=> zoneA.name.localeCompare(zoneB.name));
            } else {
                this.zoneOptions = [];
            }
        },
        editEducation(index) {
            this.education = this.reg_educations[index]
        },
        setMsgData(status, delay, className) {
            setTimeout(() => {
                this.msg_data_class = className;
                this.msg_data = status;
            }, delay * 1000)
        },
        registerEducation(order) {
            if (this.$v.education.$invalid) {
                this.ed_submited = true
            } else {
                var cur_date = new Date();
                var date_max = new Date(cur_date.setMonth(cur_date.getMonth() + 6));
                var st_full_date = new Date(this.education.ed_s_year, this.education.ed_s_month, this.education.ed_s_date);
                var ed_full_date = new Date(this.education.ed_e_year, this.education.ed_e_month, this.education.ed_e_date);
                if (st_full_date.getTime() > ed_full_date.getTime()) {
                    this.ed_error = true;
                    this.ed_error_note = this.def_lang === 'dk' ? 'Start dato skal være tidligere end slutdatoen' : 'Start date must be earlier than end date';
                } else if (st_full_date.getTime() > date_max.getTime()) {
                    this.ed_error = true;
                    this.ed_error_note = this.def_lang === 'dk' ? 'Start datoen skal være tidligere end idag or indenfor de næste 6 måneder' : 'Start date must be earlier than today or within the next 6 months.';
                } else {
                    this.ed_error = false;
                    if (order === -1) {
                        this.education.order = this.reg_educations.length;
                        this.reg_educations.push(this.education)
                    } else {
                        this.reg_educations[order] = this.education;
                    }
                    this.education = {};
                    this.ed_submited = false;
                }
            }
        },
        submitForm() {
            this.submitted = true;

            if (this.$v.form.$invalid || !this.projectSelectionValidates()) {
                this.projects_error = true;
                this.submit_error   = true;
                this.submit_error_note = this.def_lang === 'dk' ? 'Udfyld venligst alle påkrævede felter' : 'Please fill all required fields';
            } else if (!this.form.allow) {
                this.submit_error = true;
                this.submit_error_note = this.def_lang === 'dk' ? 'Tillad os at opbevare og bruge dine personlige data' : 'Allow us to use and store your personal data';
            } else {
                const language = this.def_lang === 'dk' ? 'dk' : 'en';

                this.projects_error = false;
                this.submit_error = true;

                let formData = new FormData();
                this.form.educations = this.reg_educations;
                if (this.form.project) { 
                    this.form.projects = [this.form.project];
                }

                if (this.form.selectedZones) {
                    this.form.selectedZones = this.selectedZones;
                }

                this.populateSelection();

                formData.append('language', language);
                formData.append('listforms', JSON.stringify(this.form));

                for (let k = 0; k < this.files.length; k++) {
                    formData.append(k, this.files[k]);
                }

                this.opacity = 0.5;
                axios.post(this.apiUrl + 'api/waiting-list', formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${this.token}`
                        }
                    }
                )
                    .then(res => {
                        this.opacity = 1;
                        if (res.data.success) {
                            if (this.redirectUrl && this.redirectUrl.length > 0) {
                                window.location = this.redirectUrl;
                            }

                            let res_token = this.token;
                            let api_url = this.apiUrl;

                            let corporation = this.corporation;
                            let waitingListConfig = this.waitingListConfig;
                            let projects = this.projects;
                            let unitGroups = this.unitGroups;
                            let unitTypes = this.unitTypes;
                            let zoneOptions = this.zoneOptions;
                            
                            let mappedProjects = this.mappedData.projects;
                            let selections = this.selections;

                            let res_def_lang = this.def_lang;
                            Object.assign(this.$data, this.$options.data.apply(this));
                            // set deafult values after reset
                            this.token = res_token;
                            this.apiUrl = api_url;

                            this.corporation = corporation;
                            this.waitingListConfig = waitingListConfig;
                            this.projects = projects;
                            this.unitGroups = unitGroups;
                            this.unitTypes = unitTypes;
                            this.zoneOptions = zoneOptions;

                            this.def_lang = res_def_lang;
                            this.form.professional = 'stud';
                            this.form.dial_code = '+45';
                            this.form.interestedFrom = '';
                            this.form.unitGroups = [];
                            this.form.unitTypes = [];
                            this.phone_placeholder = 'XX XX XX XX';
                            this.msg_data_text = this.def_lang === 'dk' ? 'Tak! Vi har modtaget din ansøgning .' : 'Thank you! Your application has been received.';
                            this.setMsgData(true, 0, 'bk_success_msg');
                            this.mappedData.projects = mappedProjects;

                            //Clear the selections
                            this.selections = selections;
                            this.selections.projects = selections.projects.map(project => {
                                project.selected = false;
                                project.unitTypes = [];
                                project.unitGroups= [];
                                return project;
                            });
                           
                            //Check if there is only a single project  
                            if (this.mappedData.projects.length <= 1) {
                                if (this.selections.projects.length) {
                                    this.selections.projects[0].selected = true;
                                }
                                
                                this.isSingleProject = true;
                            }
                            
                            //Clear the datepicker value
                           this.pickerProp.today = null;
                            
                        } else {
                            this.msg_data_text = this.def_lang === 'dk' ? 'Vi kan se du allerede har en aktiv ansøgning i vores system.' : 'We can see you already have an active pending application.';
                            this.setMsgData(true, 0, 'bk_error_msg');
                        }
                        this.setMsgData(false, 10, '');
                    })
                    .catch((error) => {
                        this.opacity = 1;
                        this.handleErrorAxiosResponse(error.response, 'modal');
                    });
            }
        },
        handleErrorAxiosResponse(error, mode) {
            this.responseErrorMode = mode;

            switch (error.status) {
                case 429:
                    this.responseErrorMessage = this.def_lang === 'dk' ? 'For mange forsøg. Prøv igen lidt senere.' : 'Too many attempts. Please try again a bit later.';
                    break;
                case 422:
                    this.responseErrorMessage = this.def_lang === 'dk' ? 'Udfyld venligst alle påkrævede felter' : 'Please fill all required fields';
                    break;
                default:
                    this.responseErrorMessage = this.def_lang === 'dk' ? 'Server Fejl. Prøv igen lidt senere.' : 'Server Error. Please try again a bit later.';
            }
        },
        fetchCorporationData(clientId) {
            axios.get(this.apiUrl + 'api/waiting-list/client/' + clientId + '/corporation',
                {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                })
                .then((response) => {
                    this.corporation       = response.data.corporation;
                    this.waitingListConfig = response.data.waitingList;
                    this.projects          = response.data.projects;
                    this.unitGroups        = typeof response.data.unitGroups !== 'undefined' ? response.data.unitGroups : [];
                    this.unitTypes         = typeof response.data.unitTypes !== 'undefined' ? response.data.unitTypes : [];

                    for (const project of this.projects) {
                        this.mappedData.projects.push({
                            project,
                            unitTypes: this.unitTypes.filter((unitType) => { return project.id === unitType.projectId }),
                            unitGroups: this.unitGroups.filter((unitGroup) => { return project.id === unitGroup.projectId })
                        });

                        this.selections.projects.push({
                            projectId: project.id,
                            selected: false,
                            unitTypes: [],
                            unitGroups: [],
                        });
                    }
                    
                    //There is a single project selected 
                    if(this.mappedData.projects.length <= 1) {
                        if (this.selections.projects.length) {
                            this.selections.projects[0].selected = true;
                        }

                        this.isSingleProject = true;
                    }

                    this.initializeEnabledAdditionalPreferences();
                })
                .catch((error) => {
                    if (error.status) {
                        this.handleErrorAxiosResponse(error.response, 'fullscreen');
                    }
                });
        },    
    },
    created() {
        this.validations = () => {
            return {
                form: {
                    first_name: {required},
                    last_name: {required},
                    mail: {required, email},
                    phone: {required},
                    street: {required},
                    city: {required},
                    country: {required},
                    dial_code: {required},
                    // number: {required},
                    zipcode: {required},
                    month: {required},
                    date: {required},
                    year: {required},
                    interestedFrom: {required},
                    gender: (this.$props.genderEnabled) ? {required} : {},
                },
                education: {
                    name_title: (this.$props.detailedEducation) ? {required} : {},
                    ed_s_month: (this.$props.detailedEducation) ? {required} : {},
                    ed_s_date: (this.$props.detailedEducation) ? {required} : {},
                    ed_s_year: (this.$props.detailedEducation) ? {required} : {},
                    ed_e_month: (this.$props.detailedEducation) ? {required} : {},
                    ed_e_date: (this.$props.detailedEducation) ? {required} : {},
                    ed_e_year: (this.$props.detailedEducation) ? {required} : {},
                    ed_institution: {required},
                    ed_zipcode: (this.$props.detailedEducation) ? {required} : {},
                },     
            };
        };

        this.$nextTick(() => {
            this.$v.$reset();
        })
    },
    mounted() {
        this.def_lang       = this.definedLanguage;
        let environment     = this.environment;
        this.show_see_types = (this.showSeeTypes) ? (this.showSeeTypes.toLowerCase() === 'true') : false;

        switch (environment) {
            case "production":
                this.apiUrl = "https://api.hococo.io/";
                break;

            case "staging":
                this.apiUrl = "https://api-staging.hococo.io/";
                break;

            case "test":
                this.apiUrl = "https://api-test.hococo.io/";
                break;

            case "local":
                this.apiUrl = "https://api.hococo.dev/";
                break;

            default:
                this.apiUrl = "https://api.hococo.io/";
        }

        // check for URL parameter test-environment
        let uri = window.location.search.substring(1); 
        let urlParameters = new URLSearchParams(uri);
        let testEnvironment = urlParameters.get("test-environment");

        if (testEnvironment) {
            this.testEnvironment = testEnvironment;
            this.isTestEnvironment = true;

            switch (testEnvironment) {
                case "production":
                    this.apiUrl = "https://api.hococo.io/";
                    this.clientId = 14;
                    this.clientSecret = 'PtEtDc4StI58X3VffbW63v8RHU5yt7cj47HLrTgf';
    
                    break;
    
                case "staging":
                    this.apiUrl = "https://api-staging.hococo.io/";
                    this.clientId = 14;
                    this.clientSecret = 'PtEtDc4StI58X3VffbW63v8RHU5yt7cj47HLrTgf';
                    break;
    
                default:
                    this.apiUrl = null;            
            }
        }

        let form_params = {
            'grant_type': 'client_credentials',
            'client_id': this.clientId,
            'client_secret': this.clientSecret,
            'scope': '',
        };

        let input = document.getElementById('fileInput');
        input.addEventListener('change', this.fileAttached);        

        axios.post(this.apiUrl + 'oauth/token', form_params)
            .then(res => {
                this.token = res.data.access_token;
                this.fetchCorporationData(this.clientId);
            })
            .catch((error) => {
                this.handleErrorAxiosResponse(error.response, 'fullscreen');
            });
    },
    destroyed() {
        let input = document.getElementById('fileInput');
        input.removeEventListener('change', this.fileAttached);
    }
}
</script>

<style lang="scss">
    #environment-banner {
        text-align: center;
        text-transform: uppercase;
        font-size: 2em;
    }

    .vsa-list {
        max-width: none;
        --vsa-max-width: 720px;
        --vsa-min-width: 300px;
        --vsa-text-color: rgba(55, 55, 55, 1);
        --vsa-highlight-color: rgba(0, 0, 0, 0.2);
        --vsa-bg-color: transparent;
        --vsa-border-color: rgba(0, 0, 0, 0.2);
        --vsa-border-width: 1px;
        --vsa-border-style: solid;
        --vsa-heading-padding: 1rem 1rem;
        --vsa-content-padding: 1rem 1rem;
        --vsa-default-icon-size: 1;

    }

    .vsa-item__trigger:focus,
    .vsa-item__trigger:hover {
        color: rgba(33, 33, 33, 1);
    }

    .hoc-accordion__label {
        text-transform: uppercase;
        font-weight: 700;
    }

    .vsa-item__content .multiselect {
        margin-bottom: 1em;
    }

    .vsa-item__content .btn {
        margin-bottom: 1em;
    }

    .btn-sm {
         margin-top: 0.5em;
    }
</style>