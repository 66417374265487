<template>
    <div class="hoc_">
        <MainForm 
            :definedLanguage="definedLanguage"
            :redirectUrl="redirectUrl"
            :clientId="clientId"
            :clientSecret="clientSecret"
            :environment="environment"
            :showSeeTypes="showSeeTypes"
            :genderEnabled="genderEnabled"
            :detailedEducation="detailedEducation"
        />
    </div>
</template>

<script>
import MainForm from "./components/MainForm";

export default {
    name: 'app',
    components: {
        MainForm
    },
    data() {
        return {
            clientId: null,
            definedLanguage: null,
            redirectUrl: null,
            clientSecret: null,
            environment: null,
            showSeeTypes: null,
            genderEnabled: null,
            detailedEducation: null
        };
    },
    created() {
        let element = document.getElementById('waiting-list');

        if (element) {
            this.definedLanguage       = element.getAttribute('data-lang');
            this.redirectUrl           = element.getAttribute('data-redirect-url');
            this.clientId              = element.getAttribute('data-client_id');
            this.clientSecret          = element.getAttribute('data-client_secret');
            this.environment           = element.getAttribute('data-environment');

            // config
            let showSeeTypes          = element.getAttribute('data-see-types');
            this.showSeeTypes         = (showSeeTypes === 'true');

            let genderEnabled         = element.getAttribute('data-gender');
            this.genderEnabled        = (genderEnabled === 'true');

            let detailedEducation     = element.getAttribute('data-detailed-education');
            this.detailedEducation    = (detailedEducation === 'true');
        }        
    }
}
</script>
