module.exports = {
  current_address : 'Current Address',
  address : 'Address',
  applay_now : 'Apply now',
  allow_note : 'I hereby accept the processing and storage of my personal data as described in the privacy policy and thereby give {corporationName} the right to contact me by any communication channel in order to proceed with this application. I am informed that I can withdraw my consent at any time by contacting {corporationName}. Our Privacy policy can be read ',
  birthdate : 'Birthdate',
  blakhus : '{corporationName}',
  city : 'City',
  country : 'Country',
  code : 'Code',
  cpr_disclaimer : 'Unfortunately, {corporationName} can’t accept applicants who are registered in RKI or any of it’s like registry.',
  cur_education : 'Current education',
  cur_education_note : 'Register an education you are currently taking or plan on starting within the next 6 months.',
  doc_disclaimer : '{corporationName} needs proof of education, official proof of personal identification (passport, id, etc) before we will consider your application.',
  door : 'Door',
  date : 'Date',
  documents : 'Documents',
  documents_note : 'No documents uploaded.',
  education_title : 'Education title',
  educational_institution : 'Educational institution',
  education : 'Education',
  end_date : 'End date',
  first_name : 'First Name',
  floor : 'Floor',
  field_required : 'Field is required',
  here : 'here',
  interested_from : 'Preferred move-in date',
  last_name : 'Last name',
  mail : 'Email',
  max_rent: 'Max. monthly rent',
  max_size : 'Max. size (m²)',
  month : 'Month',
  min_rent: 'Min. monthly rent',
  min_size : 'Min. size (m²)',
  none : 'None',
  number : 'Number',
  no_education : 'No education registered.',
  name_title : 'Name/title',
  phone : 'Phone',
  phone_note : 'You must register a phone number',
  profession : 'Profession',
  rooms : 'Number of rooms',
  new_graduate : 'New Graduate',
  press_enter_to_select: 'Press enter to select',
  press_enter_to_remove: 'Press enter to remove',
  professional_note : 'Young professional',
  register_education : 'Register education',
  resident_note : 'Current resident at {corporationName}',
  street : 'Street',
  student : 'Student',
  student_note : 'Student',
  see_types : 'See types',
  start_date : 'Start date',
  spec_wish : 'Specific wishes',
  select_country : 'Select Country',
  type : 'type',
  type_note : 'Type C is disability friendly',
  upload_documents : 'Upload documents',
  valid_email : 'This value should be a valid email',
  waiting_sign_up : 'Waiting list sign up',
  year : 'Year',
  zipcode : 'Zipcode',
  error_header: 'We encountered an error.',
  too_many_requests_error: 'Too many attempts. Please try again a bit later.',
  server_error: 'Server Error',
  close: 'Close',
  select_project: 'Please select the project',
  unit_groups: 'Groups',
  unit_types: 'Type',
  optional: 'Optional',
  property: 'Property',
  nationality: 'Nationality',
  i_want_to_live_here: 'I want to live here',
  select_unit_groups: 'Select unit groups (optional)',
  select_unit_types: 'Select unit types (optional)',
  select_one_or_more: 'Select one or more',
  selected: 'selected',
  gender: 'Gender',
  male: 'Male',
  female: 'Female',
  other: 'Other',
  preferences: 'Preferences',
  select_date: 'Select date',
  value_should_be_number: 'The value should be a number',
  value_should_be_decimal: 'The value should be a decimal',
  zones: 'Zones',
};
